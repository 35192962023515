import React, { FC, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import Lottie from "lottie-react";
import classNames from "classnames";
import "../../styles/callStyles.css";
import MutedIcon from "../MutedIcon";
import useAgora from "../../hooks/useAgora";
import useAlert from "../../hooks/useAlert";
import useEvent from "react-use-event-hook";
import { CallProps } from "../../libs/interface";
import { ConsultFactory } from "../../utils/funcs";
import MediaPlayer from "../MediaPlayers/MediaPlayer";
import Loading from "../../assets/lotties/loading.json";
import { endConsultationReasons } from "../../libs/mock";
import { useNavigate, useParams } from "react-router-dom";
import { useGraphQLApi } from "../../hooks/useGraphQLApi";
import { useSwitchMode } from "../../hooks/useSwitchMode";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import { SwitchRequestModal } from "../Modals/SwitchRequest";
import PoweredByWhite from "../../assets/heala-powered-white.png";
import CallControls, { ReconnectingCallControls } from "./CallControls";
import { useConsultationContext } from "../../contexts/consultationContext";
import {
  CallState,
  ConsultationDetails,
  consultationMedium,
  endConsultationReasonsType,
} from "../../libs/types";
import AgoraRTC, {
  IAgoraRTCClient,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from "agora-rtc-sdk-ng";
import {
  endConsultationOnFS,
  useListenToEndConsultation,
} from "../../utils/firestore";

const client: IAgoraRTCClient = AgoraRTC.createClient({
  codec: "h264",
  mode: "rtc",
});

let joinedCall = false;
let doctorCount = 0;

const Call: FC<CallProps> = (props) => {
  const navigate = useNavigate();
  const isOnline = useNetworkStatus();
  const { displayAlert } = useAlert();
  const { consultationId } = useParams();
  const { sendEndCommunication } = useGraphQLApi();
  const consultationDetails = useConsultationContext();
  const [count, setCount] = useState<number | null>(null);
  const listenToEndConsultation = useListenToEndConsultation();
  const [callState, setCallState] = useState<CallState>("in_call");
  const [mode, setMode] = useState<"video" | "audio" | null>(null);
  const [switchMode, setSwitchMode] = useState<consultationMedium>();
  const { channelName, appId, token, uid, timerParams, callType } = props;
  const [trackState, setTrackState] = useState({ video: false, audio: false });
  const showCallMode =
    callState === "in_call" || callState === "doctor_reconnecting";

  const { doctor, patient } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );
  const { firstName, lastName, picture } = doctor;
  const { firstName: fn, lastName: ln } = patient;

  const {
    join,
    leave,
    joinState,
    setJoinState,
    remoteUsers,
    activateVideo,
    localAudioTrack,
    localVideoTrack,
  } = useAgora(client);

  const patientsAudioAndVideo: [
    ILocalAudioTrack | undefined,
    ILocalVideoTrack | undefined
  ] = [localAudioTrack, localVideoTrack];

  const doctorAgoraInfo = remoteUsers.length > 0 ? remoteUsers[0] : null;

  const { listenToMode, changeModeInFireStore, requestModeSwitch } =
    useSwitchMode(
      consultationDetails as ConsultationDetails,
      (mode) => setSwitchMode(mode),
      leave
    );

  const acceptRejectSwitch = useCallback(
    async (response: "accepted" | "rejected") => {
      try {
        switchMode &&
          (await changeModeInFireStore({
            doctor: switchMode,
            patient: response,
          }));
        mode && setSwitchMode(mode as consultationMedium);
      } catch (error) {
        console.error(error);
        const err = error as Error;
        displayAlert("error", err?.message);
        mode && setSwitchMode(mode as consultationMedium);
      }
    },
    [switchMode]
  );

  const requestModeChange = useCallback(async (newMode: consultationMedium) => {
    try {
      await requestModeSwitch(newMode);
    } catch (error) {
      console.error(error);
      const errMsg = error as Error;
      displayAlert("error", errMsg?.message);
    }
  }, []);

  const leaveAgoraChannel = async () => {
    leave()
      .then(() => {
        if (callState === "doctor_reconnecting") {
          navigate(`/rate-consultation/${consultationId}?issuefrom=doctor`);
        } else {
          navigate(`/rate-consultation/${consultationId}`);
        }
      })
      .catch((error) => {
        console.error(error?.code);
        throw new Error(error);
      });
  };

  const discontinueCommunication = async (
    reason: endConsultationReasonsType
  ) => {
    try {
      localStorage.removeItem("consultationStartTime");
      setJoinState(false);
      await sendEndCommunication(channelName, reason, leaveAgoraChannel);
    } catch (error) {
      setJoinState(false);
      console.error("Failed to end communication", error);
    }
  };

  const autoJoin = useEvent(
    debounce(() => {
      join(appId, channelName, token, uid, callType);
      joinedCall = true;
      setCallState((prev) => {
        if (prev === "init_call") {
          return "in_call";
        } else {
          return prev;
        }
      });
    }, 1000)
  );

  const mute = async (type: "audio" | "video") => {
    const [audio, video] = [localAudioTrack, localVideoTrack];

    try {
      if (type === "audio" && audio) {
        await audio.setMuted(!trackState.audio);
        setTrackState((ps) => {
          return { ...ps, audio: !ps.audio };
        });
      } else if (type === "video" && video) {
        await video.setMuted(!trackState.video);

        setTrackState((ps) => {
          return { ...ps, video: !ps.video };
        });
      }
    } catch (error) {
      displayAlert("error", `Couldn't mute ${type}`);
      console.error("Error from mute function", error);
    }
  };

  const listenToSwitch = useEvent(() => listenToMode());

  // LISTEN TO FIRE STORE SWITCH MODE
  useEffect(() => {
    const unsubscribeFn = listenToSwitch();
    return () => {
      unsubscribeFn();
    };
  }, []);

  useEffect(() => {
    if (!localVideoTrack) {
      setTrackState((prevState) => {
        return { ...prevState, video: true };
      });
    } else {
      setTrackState((prevState) => {
        return { ...prevState, video: false };
      });
    }
  }, [localVideoTrack]);

  // END CONSULTATION WHEN TIMER RUNS OUT
  useEffect(() => {
    if (timerParams.timerEnded) {
      endConsultationOnFS(`${consultationId}`, () =>
        discontinueCommunication(endConsultationReasons.timeout)
      );
    }
  }, [timerParams.timerEnded]);

  // JOIN CALL
  useEffect(() => {
    if (joinState) joinedCall = true;
    if (!joinState) {
      autoJoin();
    }
  }, []);

  // SET CALL MODE (VIDEO OR AUDIO)
  useEffect(() => {
    setMode(callType);
  }, []);

  /*
   * START COUNTDOWN WHEN DOCTOR HAS CONNECTIVITY ISSUES
   * STOP COUNTDOWN WHEN DOCTOR RECONNECTS BEFORE COUNTDOWN RUNS OUT
   */
  useEffect(() => {
    if (!doctorAgoraInfo && joinedCall && callState === "in_call") {
      setCount(120);
      setCallState("doctor_reconnecting");
    }

    if (doctorAgoraInfo) {
      doctorCount++;
    }

    if (
      doctorAgoraInfo &&
      doctorCount > 0 &&
      callState !== "patient_disconnected"
    ) {
      setCount(null);
      setCallState("in_call");
    }
  }, [doctorAgoraInfo]);

  /*
   * START COUNTDOWN WHEN PATIENT HAS NETWORK ISSUES
   * STOP COUNTDOWN WHEN PATIENT RECONNECTS BEFORE COUNTDOWN RUNS OUT
   */
  useEffect(() => {
    if (!isOnline) {
      setCount(120);
      setCallState("patient_reconnecting");
    }

    if (isOnline && callState === "patient_reconnecting") {
      setCount(null);
      setCallState("in_call");
    }
  }, [isOnline]);

  // COUNTDOWN
  useEffect(() => {
    if (typeof count !== "number") return;

    const timer = setTimeout(() => {
      setCount((prevCount) => {
        if (!prevCount || prevCount < 1) return prevCount;
        return prevCount - 1;
      });
    }, 1000);

    if (count === 60) {
      callState === "doctor_reconnecting" &&
        setCallState("doctor_disconnected");
      callState === "patient_reconnecting" &&
        setCallState("patient_disconnected");
    }

    if (count < 1) {
      clearTimeout(timer);
      setCount(null);
      if (callState === "doctor_disconnected") {
        endConsultationOnFS(`${consultationId}`, () =>
          sendEndCommunication(
            channelName,
            endConsultationReasons.doctorDisconnected,
            async () => {
              await leave();
              await navigate(
                `/rate-consultation/${consultationId}?issuefrom=doctor`
              );
            }
          )
        );
      }
      if (callState === "patient_disconnected") {
        endConsultationOnFS(`${consultationId}`, () =>
          sendEndCommunication(
            channelName,
            endConsultationReasons.patientDisconnected,
            async () => {
              await leave();
              await navigate(
                `/rate-consultation/${consultationId}?issuefrom=user`
              );
            }
          )
        );
      }
    }

    return () => clearTimeout(timer);
  }, [count]);

  // LISTEN FOR WHEN DOCTOR ENDS CALL AND LEAVE THE CALL
  useEffect(() => {
    const unsubscribe = listenToEndConsultation(`${consultationId}`, () =>
      discontinueCommunication(endConsultationReasons.doctorEnded)
    );

    return () => {
      unsubscribe.then((unsubscribeFN) => unsubscribeFN());
    };
  }, []);

  return (
    <div className={`${mode ? "block" : "hidden"} w-full`}>
      {callState === "init_call" ? ( // add switch call mode later
        <div className="w-full h-full bg-[#313131] flex-col justify-end relative">
          <div className="h-full flex justify-center items-center text-white space-x-3">
            <p>JOINING</p>
            <Lottie animationData={Loading} style={{ width: 25 }} />
          </div>
        </div>
      ) : (
        <>
          {/* ========================= VIDEO MODE ======================== */}
          <div
            className={classNames(
              `w-full h-full bg-[#313131] flex-col justify-end relative`,
              {
                hidden: mode === "audio" || !showCallMode,
                flex: mode === "video" || showCallMode,
              }
            )}
          >
            <div
              id="videos"
              className={` block lg:flex justify-center items-center flex-1 gap-5 lg:p-5 relative transition ease-in-out delay-150`}
            >
              {/* ========= PATIENT VIDEO ========== */}
              <div
                className={classNames(
                  "h-[200px] w-[150px] md:w-[200px] md:h-[250px] absolute right-2 top-10 z-30 md:top-2 lg:static lg:h-full transition-all ease-in-out duration-500",
                  {
                    "visible lg:w-full": Boolean(localVideoTrack),
                    "invisible lg:w-0": Boolean(!localAudioTrack),
                  }
                )}
              >
                <MediaPlayer
                  name="localTracks"
                  videoTrack={localVideoTrack}
                  audioTrack={undefined}
                  userInfo={{ firstName: fn, lastName: ln }}
                  hasAudio={localAudioTrack?.muted || false}
                  hasVideo={!localVideoTrack?.muted}
                />
              </div>

              {/* =========== DOCTOR VIDEO ============ */}
              <div className=" w-full h-screen lg:w-full lg:h-full">
                <div className=" h-full flex-1 transition-all ease-in-out duration-500">
                  {callState === "in_call" && doctorAgoraInfo ? (
                    <MediaPlayer
                      name="remoteTracks"
                      videoTrack={doctorAgoraInfo?.videoTrack}
                      audioTrack={undefined}
                      userInfo={{ firstName, lastName }}
                      hasAudio={doctorAgoraInfo.hasAudio || false}
                      hasVideo={doctorAgoraInfo.hasVideo}
                    />
                  ) : (
                    <ConnectingStatus name={firstName} count={doctorCount} />
                  )}
                </div>
              </div>
            </div>
            {/* ====== VIDEO CONTROLS ========= */}
            <div className="absolute right-0 left-0 bottom-0 z-50 lg:static mb-2">
              <CallControls
                onHandleClickChat={() => requestModeChange("chat")}
                activateVideoTrack={async () => {
                  try {
                    if (callType === "video") {
                      return;
                    }
                    if (mode === "audio") {
                      !patientsAudioAndVideo[1] && (await activateVideo());
                      setMode("video");
                    } else {
                      localVideoTrack && (await localVideoTrack.setMuted(true));
                      setMode("audio");
                    }
                  } catch (error) {
                    displayAlert(
                      "error",
                      "Switching to video mode failed, try Again!"
                    );
                    console.error("error from switching to video mode", error);
                  }
                }}
                tracks={patientsAudioAndVideo}
                endCommunication={() =>
                  endConsultationOnFS(`${consultationId}`, () =>
                    discontinueCommunication(endConsultationReasons.callEnded)
                  )
                }
                callType={callType}
                mode={mode}
                trackState={trackState}
                mute={(type) => mute(type)}
              />
            </div>
          </div>
          {/* ======================== AUDIO MODE ========================= */}
          <div
            className={classNames(`h-full items-end bg-[#313131]`, {
              hidden: mode === "video" || !showCallMode,
              flex: mode === "audio" || showCallMode,
            })}
          >
            <div
              className={`w-full h-full flex flex-col justify-between items-stretch pt-5`}
            >
              {/* ============= DOCTOR INFORMATION =============== */}
              <div className="flex flex-col items-center justify-center flex-1 space-y-4">
                {callState === "in_call" && doctorAgoraInfo ? (
                  <>
                    <div className="relative rounded-full">
                      <div className="max-w-[150px] max-h-[150px] w-[100px] h-[100px] lg:w-[150px] lg:h-[150px] scale-up-center bg-slate-700 rounded-full absolute z-0"></div>
                      <img
                        src={`${picture || ""}`}
                        alt={`Dr. ${firstName}`}
                        className=" max-w-[150px] max-h-[150px] w-[100px] h-[100px] lg:w-[150px] lg:h-[150px] bg-white flex justify-center items-center rounded-full relative z-10"
                      />
                    </div>
                    <div className="flex items-center space-x-2 transition ease-in-out">
                      <p className="text-white font-semibold text-xl lg:text-2xl leading-[38.04px]">
                        {firstName && `Dr. ${firstName}`}
                      </p>
                      {doctorAgoraInfo &&
                        doctorAgoraInfo.hasAudio === false && <MutedIcon />}
                    </div>
                  </>
                ) : (
                  <ConnectingStatus name={firstName} count={doctorCount} />
                )}
              </div>
              {/* ====== AUDIO CONTROLS ========= */}
              <div className=" space-y-4">
                <CallControls
                  onHandleClickChat={() => requestModeChange("chat")}
                  activateVideoTrack={async () => {
                    try {
                      if (mode === "audio") {
                        !patientsAudioAndVideo[1]
                          ? await activateVideo()
                          : await localVideoTrack?.setMuted(false);
                        setMode("video");
                      } else {
                        setMode("audio");
                      }
                    } catch (error) {
                      displayAlert(
                        "error",
                        "Switching to video mode failed, try Again!"
                      );
                      console.error(
                        "error from switching to video mode",
                        error
                      );
                    }
                  }}
                  tracks={patientsAudioAndVideo}
                  endCommunication={() =>
                    endConsultationOnFS(`${consultationId}`, () =>
                      discontinueCommunication(endConsultationReasons.callEnded)
                    )
                  }
                  callType={callType}
                  mode={mode}
                  trackState={trackState}
                  mute={(type) => mute(type)}
                />
                <div className="flex justify-center items-center mb-4">
                  <p className="text-white text-sm leading-8 font-light tracking-[0.25%]">
                    Powered by
                  </p>
                  <img
                    src={PoweredByWhite}
                    alt="HEALA Logo"
                    className="mt-1 ml-2"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ======================== RECONNECTING MODE ========================= */}
          <ReconnectingMode
            show={callState === "doctor_disconnected"}
            text={`Dr. ${firstName} has left the call. Waiting to rejoin call.`}
            cta={
              <div>
                <ReconnectingCallControls
                  endCommunication={async () => {
                    setCount(null);
                    endConsultationOnFS(`${consultationId}`, () =>
                      sendEndCommunication(
                        channelName,
                        endConsultationReasons.patientEndsDoc,
                        async () => {
                          await leave();
                          await navigate(
                            `/rate-consultation/${consultationId}?issuefrom=doctor`
                          );
                        }
                      )
                    );
                  }}
                />
              </div>
            }
          />
          <ReconnectingMode
            show={callState === "patient_disconnected"}
            text={`Sorry we could not reconnect you to the call. Check your internet connection and try to rejoin.`}
            cta={
              <div className="flex space-x-3 px-4">
                <button
                  // disabled={!isOnline}
                  onClick={() => {
                    if (!isOnline) {
                      displayAlert("info", "No internet connection!");
                      return;
                    }
                    if (isOnline) {
                      setCount(null);
                      setCallState("in_call");
                    }
                  }}
                  className="w-full h-[46px] bg-[#3E5EA9] text-white rounded-lg"
                >
                  Rejoin
                </button>
                <button
                  // disabled={loading || cancelConsultationLoading}
                  onClick={async () => {
                    setCount(null);
                    endConsultationOnFS(`${consultationId}`, () =>
                      sendEndCommunication(
                        channelName,
                        endConsultationReasons.patientEnds,
                        async () => {
                          await leave();
                          await navigate(
                            `/rate-consultation/${consultationId}?issuefrom=user`
                          );
                        }
                      )
                    );
                  }}
                  className="w-full h-[46px] border border-[#3E5EA9] text-[#3E5EA9] rounded-lg"
                >
                  Dismiss
                </button>
              </div>
            }
          />
          <ReconnectingMode
            show={callState === "patient_reconnecting"}
            text={`You lost network connection. Trying to reconnect. `}
            cta={
              <div>
                <ReconnectingCallControls
                  endCommunication={async () => {
                    setCount(null);
                    endConsultationOnFS(`${consultationId}`, () =>
                      sendEndCommunication(
                        channelName,
                        endConsultationReasons.patientEnds,
                        async () => {
                          await leave();
                          await navigate(
                            `/rate-consultation/${consultationId}?issuefrom=user`
                          );
                        }
                      )
                    );
                  }}
                />
              </div>
            }
          />
        </>
      )}

      {/* REQUEST MODAL */}
      <SwitchRequestModal
        mode={`${switchMode}` as consultationMedium}
        open={switchMode === "chat"}
        doctorName={`Dr. ${firstName} ${lastName}`}
        onAccept={() => acceptRejectSwitch("accepted")}
        onReject={() => acceptRejectSwitch("rejected")}
      />
    </div>
  );
};

export default Call;

const ConnectingStatus = ({ name, count }: { name: string; count: number }) => {
  return (
    <div className="h-full flex flex-col lg:flex-row justify-center items-center text-white space-x-3 px-4 md:px-2 space-y-3 lg:space-y-0 lg:px-0">
      <p className="text-center">
        Dr. {name} is {count > 0 ? "reconnecting" : "connecting"}
      </p>
      <Lottie animationData={Loading} style={{ width: 30 }} />
    </div>
  );
};

const ReconnectingMode = ({
  cta,
  show,
  text,
}: {
  show: boolean;
  text: string;
  cta: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(`h-full items-end bg-[#313131]`, {
        hidden: !show,
        flex: show,
      })}
    >
      <div
        className={`w-full h-full flex flex-col justify-between items-stretch pt-5`}
      >
        <div className="flex flex-col items-center justify-center flex-1 space-y-4">
          <div className="h-full flex flex-col lg:flex-row justify-center items-center text-white space-x-3 space-y-3 lg:space-y-0">
            <p className="text-center text-base md:text-xl lg:text-2xl">
              {text}
            </p>
            <Lottie animationData={Loading} style={{ width: 30 }} />
          </div>
        </div>
        {/* ====== REJOINING CONTROLS ========= */}
        <div className=" space-y-4">
          {cta}
          <div className="flex justify-center items-center mb-4">
            <p className="text-white text-sm leading-8 font-light tracking-[0.25%]">
              Powered by
            </p>
            <img src={PoweredByWhite} alt="HEALA Logo" className="mt-1 ml-2" />
          </div>
        </div>
      </div>
    </div>
  );
};
