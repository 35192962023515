import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createClient } from "graphql-ws";
import { SnackbarProvider } from "notistack";
import reportWebVitals from "./reportWebVitals";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { ConsultationProvider } from "./contexts/consultationContext";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
  ApolloLink,
  concat,
} from "@apollo/client";
import * as Sentry from "@sentry/react";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

Sentry.init({
  dsn: "https://3776aeac0dd84599bba18973f56e72fa@o4505028684808192.ingest.sentry.io/4505035719966720",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const wsLink = new GraphQLWsLink(
  createClient({
    //eslint-disable-next-line
    url: `${apiBaseUrl}`,
  })
);

// SHOW GRAPHQL ERROR BUT USE NOTISTACK
/* const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
          alert(`Graphhql error ${message}`);
      });
  } //
}); */

const httpLink = new HttpLink({
  //eslint-disable-next-line
  uri: apiBaseUrl,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const accessToken = sessionStorage.getItem("access_token");
  const auth = accessToken ? { authorization: `bearer ${accessToken}` } : {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operation.setContext((context: any) => ({
    headers: {
      ...context.headers,
      ...auth,
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache(),
  link: concat(authMiddleware, splitLink),
  resolvers: {},
});

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <ApolloProvider client={client}>
        <ConsultationProvider>
          <App />
        </ConsultationProvider>
      </ApolloProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
