import React, { useContext, useRef } from "react";
import useAlert from "./useAlert";
import firebaseApp from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { useGraphQLApi } from "./useGraphQLApi";
import { ConsultFactory, guessMode } from "../utils/funcs";
import {
  getFirestore,
  doc,
  onSnapshot,
  Firestore,
  updateDoc,
} from "firebase/firestore";
import {
  ConsultationDetails,
  consultationMedium,
  consultationMode,
} from "../libs/types";
import { AppContext } from "../App";

const db = getFirestore(firebaseApp) as Firestore;

export const useSwitchMode = (
  consultationDetails: ConsultationDetails,
  requestSwitch: (mode: consultationMedium) => void,
  onSwitchMode: () => Promise<unknown>
) => {
  const navigate = useNavigate();
  const { displayAlert } = useAlert();
  const currentMode = useRef<consultationMode>();
  const { setShowAwaitingRes } = useContext(AppContext);
  const { initVideoAudioConsultation } = useGraphQLApi();

  const { doctor, patient, _id } = React.useMemo(
    () => ConsultFactory(consultationDetails),
    [consultationDetails]
  );

  // CHANGE MODE IN FIREBASE
  const changeModeInFireStore = async (newMode: consultationMode) =>
    await updateDoc(doc(db, "Consultation", _id as string), { mode: newMode });

  // REQUEST SWITCH TO VIDEO VOICE OR CHAT
  const requestModeSwitch = async (newMode: consultationMedium) => {
    const doctorMode = currentMode.current?.doctor;
    await updateDoc(doc(db, "Consultation", _id as string), {
      mode: { doctor: doctorMode, patient: newMode },
    });
  };

  // LISTEN TO FIREBASE FOR CHANGES
  const listenToMode = () => {
    const unsubscribe = onSnapshot(
      doc(db, "Consultation", _id as string),
      (doc) => {
        const newMode = doc.data()?.mode ?? {};
        currentMode.current = newMode;
        interpretMode(newMode);
      }
    );
    return unsubscribe;
  };

  // INTERPRET MODE FROM FIREBASE
  const interpretMode = (mode: consultationMode) => {
    const action = guessMode(mode);
    EventsObject[action]();
  };

  // SWITCH TO CHAT, VOICE OR VIDEO CONSULTATION
  const switchMode = async (mode: consultationMedium) => {
    setShowAwaitingRes(false);
    changeModeInFireStore({ doctor: null, patient: null });
    const roomId = `${_id}_${doctor._id}_${patient._id}`;
    const agoraInfo = sessionStorage.getItem("agoraInfo");
    if (mode === "voice") {
      !agoraInfo && (await initVideoAudioConsultation(`${_id}`, true));
      return navigate(`/voice/${_id}`, { replace: true });
    }
    if (mode === "video") {
      !agoraInfo && (await initVideoAudioConsultation(`${_id}`, true));
      return navigate(`/video/${_id}`);
    }

    navigate(`/chat/${roomId}`);
  };

  // STORE ALL EVENTS FUNCTIONS
  const EventsObject: Record<ReturnType<typeof guessMode>, () => void> = {
    unknown() {
      console.error(">>> unknown mode");
    },

    declined() {
      setShowAwaitingRes(false);
      displayAlert(
        "error",
        `Request to switch mode was declined by the Doctor.`
      );
      changeModeInFireStore({ doctor: null, patient: null });
    },

    requesting() {
      setShowAwaitingRes(true);
    },

    request_for_chat() {
      requestSwitch("chat");
    },

    request_for_video() {
      requestSwitch("video");
    },

    request_for_voice() {
      requestSwitch("voice");
    },

    switch_to_chat() {
      onSwitchMode()
        .then(() => switchMode("chat"))
        .catch((error) => console.error(error));
    },

    switch_to_video() {
      switchMode("video");
    },

    switch_to_voice() {
      switchMode("voice");
    },
  };

  return { listenToMode, changeModeInFireStore, requestModeSwitch };
};
