import { gql } from "@apollo/client";

export const getConsultation = gql`
  query getConsultation($consultationId: ID!) {
    getConsultation(data: { id: $consultationId }) {
      _id
      status
      consultationOwner
      doctor {
        _id
        firstName
        lastName
        gender
        phoneNumber
        dociId
        hospital
        specialization
        picture
        dob
        cadre
        rating
        providerId
        accountDetails {
          accountName
          accountNumber
          bankName
          nuban
          recipientCode
        }
      }
      patient {
        _id
        firstName
        lastName
        gender
        phoneNumber
        dociId
        dob
        image
        providerId
        timezoneOffset
        hmoId
        email
      }
      companyId {
        _id
      }
      providerId
      patientJoined
      doctorJoined
      declineReason
      consultationDuration
      contactMedium
      createdThrough
      type
      time
      createdAt
      updatedAt
      referralId
      firstNotice
      description
      discomfortLevel
      symptoms {
        name
      }
      isFollowUp
      followUpConsultationId
      principalHmoId
      pharmacyCode
      pharmacyName
      pharmacyAddress
    }
  }
`;

export const accessConsultation = gql`
  query accessConsultation($consultationId: ID!) {
    accessConsultation(data: { id: $consultationId }) {
      message
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        access_token
        role {
          _id
          name
          description
        }
        providerId
        userTypeId {
          _id
        }
        enrolleeNumber
        isPasswordTemporary
      }
    }
  }
`;

export const getProviderLogo = gql`
  query getProvider($providerId: String!) {
    getProvider(id: $providerId) {
      _id
      name
      icon
      iconAlt
    }
  }
`;
